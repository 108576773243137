import React from "react"
import ContactForm from "../../components/ContactForm/ContactForm"
import SEO from "../../components/Helmet/SEO"
import PageLayout from "../../components/PageLayout/PageLayout"

const Contact = () => {
  return (
    <>
      <SEO title="Kontakt" />
      <PageLayout>
        <ContactForm isThankyou={false} />
      </PageLayout>
    </>
  )
}

export default Contact
